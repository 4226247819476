export const fonts = {
  sans: 'DM Sans, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  sansItalic:
    'DM Sans Italic, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
} as const;

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
} as const;

export const fontSizes = {
  displayLg: 58,
  displayMd: 51,
  displaySm: 45,
  headlineLg: 40,
  headlineMd: 36,
  headlineSm: 32,
  titleLg: 22,
  titleMd: 16,
  titleSm: 14,
  bodyLg: 16,
  bodyMd: 14,
  bodySm: 12,
  labelLg: 16,
  labelMd: 14,
  labelSm: 12,
} as const;

export const lineHeights = {
  none: 1,
  tight: 1.2,
  snug: 1.3,
  normal: 1.5,
} as const;

export const borderRadius = {
  r4: 4,
  r8: 8,
  r12: 12,
  r16: 16,
  r25: 25,
  r32: 32,
  r64: 64,
} as const;

export const space = {
  s1: 1,
  s2: 2,
  s3: 3,
  s4: 4,
  s5: 5,
  s6: 6,
  s8: 8,
  s10: 10,
  s12: 12,
  s14: 14,
  s16: 16,
  s20: 20,
  s24: 24,
  s26: 26,
  s28: 28,
  s32: 32,
  s35: 35,
  s40: 40,
  s48: 48,
  s56: 56,
  s60: 60,
  s64: 64,
  s70: 70,
  s72: 72,
  s120: 120,
} as const;

// took it from tailwind. react-aria uses same. TODO: clarify with designers
export const breakpoints = {
  sm: '375px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1440px',
  '3xl': '1604px',
} as const;

export const boxShadows = {
  default: `0px 2px 10px rgba(0, 0, 0, 0.1)`,
  hover: `0px 0px 15px rgba(0, 0, 0, 0.25)`,
} as const;

export const zIndex = {
  max: 2147483647,
};
